<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <b-form>
        <div>
          <h1 class="mr-sm-4 header-tablepage">AUTOMATION DETAIL</h1>
        </div>
        <b-tabs
          class="mt-3 mb-lg-2"
          style="margin-bottom: 8rem"
          v-model="tabIndex"
        >
          <b-tab title="Set Up Automation" active>
            <!-- general information -->
            <div class="bg-white black-color">
              <div class="bg-tabs-custom w-100">
                <b-row class="m-0">
                  <b-col class="text-left">
                    <button
                      @click.prevent="showCollapse(1)"
                      class="w-100 text-left btn-expand bg-tabs-custom"
                    >
                      <b-form-checkbox v-model="generalInformationSelect"
                        >General Information</b-form-checkbox
                      >
                    </button>
                  </b-col>
                  <b-col class="text-right" v-if="!showInformation">
                    <button
                      @click.prevent="showCollapse(1)"
                      class="btn-edit w-100 text-right"
                    >
                      Edit
                    </button>
                  </b-col>
                  <b-col v-else class="text-right">
                    <button
                      class="btn-edit-general btn-line-right py-0"
                      @click.prevent="saveInformation"
                    >
                      Save
                    </button>
                    <button
                      class="btn-edit-general py-0"
                      @click.prevent="hideCollapse"
                    >
                      Cancel
                    </button>
                  </b-col>
                </b-row>
              </div>
              <div v-if="showInformation">
                <GeneralInformationPanel
                  :form="form"
                  :v="$v"
                  :eventList="eventList"
                  :campaignList="campaignList"
                  :connectChannel="connectChannel"
                  :missionList="missionOptions"
                  @handleEventTypeChange="handleEventTypeChange"
                />
              </div>
            </div>
            <div class="bg-white mt-3 black-color">
              <div class="bg-tabs-custom w-100">
                <b-row class="m-0">
                  <b-col>
                    <button
                      @click.prevent="sideBarLimitUser"
                      class="w-100 text-left btn-expand bg-tabs-custom"
                    >
                      <b-form-checkbox v-model="isLimitSelect"
                        >Limit Per User</b-form-checkbox
                      >
                    </button>
                  </b-col>
                  <b-col class="text-right">
                    <button
                      class="btn-edit w-100 text-right"
                      @click.prevent="sideBarLimitUser"
                    >
                      Edit
                    </button>
                  </b-col>
                </b-row>
              </div>
              <div class="p-3">
                <div v-if="form.is_limit == 0">Limit: No Limit</div>
                <div v-else>
                  <div
                    v-if="
                      form.user_limit_times == 0 && form.user_limit_days == 0
                    "
                  >
                    Limit: No Limit
                  </div>
                  <div v-else>
                    Limit: {{ form.user_limit_times }} Message in
                    {{ form.user_limit_days }} Days
                  </div>
                </div>
              </div>
            </div>
            <!-- condition -->
            <div class="bg-white mt-3 black-color">
              <div class="bg-tabs-custom w-100">
                <b-row class="m-0">
                  <b-col>
                    <button
                      @click.prevent="sideBarCondition"
                      class="w-100 text-left btn-expand bg-tabs-custom"
                    >
                      <b-form-checkbox v-model="conditionSelect"
                        >Conditions</b-form-checkbox
                      >
                    </button>
                  </b-col>
                  <b-col class="text-right">
                    <button
                      class="btn-edit w-100 text-right"
                      @click.prevent="sideBarCondition"
                    >
                      Edit
                    </button>
                  </b-col>
                </b-row>
              </div>
              <div class="p-3" v-if="conditionSettingValue">
                <DisplayResultSendMessageGroup
                  :customerTag="customerTag"
                  :conditionList="conditionList"
                  :gender="genderList"
                  :settingValue="conditionSettingValue"
                  :memberLevel="memberLevel"
                  :privilegeList="allPrivilegeCode"
                  :branchList="branchList"
                  :productList="productList"
                  :redeemItemList="redeemItemList"
                  :stampList="stampList"
                  :campaignFieldConditions="campaignFieldConditions"
                  :form="form"
                  :segmentList="segmentList"
                  :display="1"
                />
              </div>
            </div>
            <div class="bg-white mt-3 black-color">
              <Goal
                :form="form"
                @submitGoal="submitGoal"
                id="goal-panel"
                ref="goal"
              />
            </div>
            <div class="bg-white mt-3 black-color">
              <div class="bg-tabs-custom w-100">
                <b-row class="m-0">
                  <b-col>
                    <button
                      @click.prevent="sidebarConsent"
                      class="w-100 text-left btn-expand bg-tabs-custom"
                    >
                      <b-form-checkbox v-model="isConsentSet" class="pointer"
                        >Consent Status</b-form-checkbox
                      >
                    </button>
                  </b-col>
                  <b-col class="text-right">
                    <button
                      class="btn-edit w-100 text-right"
                      v-if="!disableStatus"
                      @click.prevent="sidebarConsent"
                    >
                      Edit
                    </button>
                  </b-col>
                </b-row>
              </div>
              <div class="p-3" v-if="isConsentSet">
                <b-row
                  ><b-col
                    >Consents Status :
                    {{
                      form.is_not_consent_send == 0
                        ? "Consent"
                        : form.is_not_consent_send == 2
                        ? "Not Consent"
                        : "All"
                    }}</b-col
                  ></b-row
                >
              </div>
            </div>
            <!-- action -->
            <div class="bg-white mt-3 black-color">
              <div class="bg-tabs-custom w-100">
                <b-row class="m-0">
                  <b-col>
                    <button
                      @click.prevent="sideBarAction"
                      class="w-100 text-left btn-expand bg-tabs-custom"
                    >
                      <b-form-checkbox v-model="actionSelect"
                        >Privilege Use</b-form-checkbox
                      >
                    </button>
                  </b-col>
                  <b-col class="text-right">
                    <button
                      class="btn-edit w-100 text-right"
                      @click.prevent="sideBarAction"
                    >
                      Edit
                    </button>
                  </b-col>
                </b-row>
              </div>
              <div class="p-3" v-if="actionSettingValue && actionSelect">
                <DisplayResultSendMessageGroup
                  :form="form"
                  :display="2"
                  :settingValue="actionSettingValue"
                  :redeemCodeList="redeemCodeList"
                  :missionList="missionList"
                />
              </div>
            </div>
            <div class="bg-white mt-3 black-color">
              <div class="bg-tabs-custom w-100">
                <b-row class="m-0">
                  <b-col>
                    <button
                      @click.prevent="$refs.sidebarMemberTier.show()"
                      class="w-100 text-left btn-expand bg-tabs-custom"
                    >
                      <b-form-checkbox v-model="attrSelect"
                        >Set Customer's Attribute</b-form-checkbox
                      >
                    </button>
                  </b-col>
                  <b-col class="text-right">
                    <button
                      class="btn-edit w-100 text-right"
                      @click.prevent="$refs.sidebarMemberTier.show()"
                    >
                      Edit
                    </button>
                  </b-col>
                </b-row>
              </div>
              <div class="p-3" v-if="attrSelect">
                <DisplayResultSendMessageGroup
                  :form="form"
                  :display="5"
                  :richMenuOptions="richMenuOptions"
                  :settingValue="attrSettingValue"
                  :memberLevel="memberLevel"
                />
              </div>
            </div>
            <!-- communication -->
            <div class="bg-white mt-3 black-color">
              <div class="bg-tabs-custom w-100">
                <b-row class="m-0">
                  <b-col class="text-left">
                    <button
                      class="w-100 text-left btn-expand bg-tabs-custom"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseExample"
                      aria-expanded="false"
                      aria-controls="collapseExample"
                    >
                      <b-form-checkbox
                        v-model="communicationSelect"
                        @click.native.prevent
                        >Channels</b-form-checkbox
                      >
                    </button>
                  </b-col>
                </b-row>
              </div>

              <div
                :class="
                  id === '0'
                    ? 'marginbottom collapse'
                    : 'marginbottom collapse show'
                "
                id="collapseExample"
              >
                <b-row class="my-3 mx-1">
                  <!-- SMS -->
                  <b-col md="4">
                    <DisplayCommunication
                      :title="'Message'"
                      :edit="smsEdit"
                      :value="form.list_communication"
                      @sideBarOpen="sideBarSMS"
                      @deleteMessage="deleteMessage"
                      :idCommu="2"
                      :estimatedCustomer="estimatedCustomer"
                      @modalTestSend="modalTestSend"
                    />
                  </b-col>
                  <!-- Email -->
                  <b-col md="4">
                    <DisplayCommunication
                      :title="'Email'"
                      :edit="emailEdit"
                      :value="form.list_communication"
                      @sideBarOpen="sideBarEmail"
                      @deleteMessage="deleteMessage"
                      :idCommu="1"
                      :estimatedCustomer="estimatedCustomer"
                      @modalTestSend="modalTestSend"
                    />
                  </b-col>
                  <!-- Line -->
                  <b-col md="4">
                    <DisplayCommunicationLine
                      :title="'Line'"
                      :edit="lineEdit"
                      :value="form.list_communication"
                      @sideBarOpen="sideBarLine"
                      :idCommu="3"
                      @modalTestSend="modalTestSend"
                      :estimatedCustomer="estimatedCustomer"
                      @deleteMessage="deleteMessage"
                    />
                  </b-col>
                  <!-- Noti -->
                  <!-- <b-col md="3">
                    <DisplayCommunication
                      :title="'Notification'"
                      :edit="notiEdit"
                      :value="form.list_communication"
                      @sideBarOpen="sidebarNotification"
                      @deleteMessage="deleteMessage"
                      :idCommu="4"
                      :estimatedCustomer="estimatedCustomer"
                      @modalTestSend="modalTestSend"
                    />
                  </b-col> -->
                </b-row>
                <b-row class="mb-3 mx-1">
                  <b-col>
                    <b-form-checkbox
                      v-model="form.sent_all"
                      :value="1"
                      :unchecked-value="0"
                    >
                      Send Messages On All Channels
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row class="mx-1">
                  <b-col md="4">
                    <InputText
                      textFloat="Delay Time (Minute)"
                      placeholder="Delay Time (Minute)"
                      type="number"
                      name="delay_time"
                      :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 4);'"
                      v-model="form.delay_time"
                    />
                  </b-col>
                </b-row>
              </div>
            </div>
            <div class="footer-action">
              <b-col>
                <router-link to="/automation">
                  <b-btn class="btn-cancel btn-width">Cancel</b-btn>
                </router-link>
                <b-btn
                  variant="danger"
                  v-if="id !== '0'"
                  class="btn-cancel btn-width ml-sm-2 mt-2 mt-sm-0"
                  @click.prevent="deleteAutomationFunc"
                >
                  Delete
                </b-btn>
              </b-col>
              <b-col class="text-right">
                <b-btn
                  class="mr-sm-2"
                  style="width: 125px"
                  variant="info"
                  @click.prevent="sendForm(1)"
                  v-if="id == 0 || form.is_draft == 1"
                >
                  Save Draft
                </b-btn>
                <b-btn
                  class="main-color btn-save mt-2 mt-sm-0"
                  @click="sendForm(0)"
                >
                  Save
                </b-btn>
              </b-col>
            </div>
          </b-tab>
          <b-tab title="Customer List" justified v-if="id !== '0'">
            <DisplayOverviewReport
              v-if="userData"
              :userData="userData"
              :headerData="headerData"
              :filter="filter"
              :isBusy="isBusy"
              :id="id"
              :fields="fields"
              :showingTo="showingTo"
              :rows="rows"
              :type="'automation'"
              @searchUserRegister="searchUserRegister"
              @getDataInPagination="getDataInPagination"
            />
          </b-tab>
        </b-tabs>
      </b-form>
      <SidebarLimitUser
        ref="sideBarLimitUser"
        :selected="form.is_limit"
        :form="form"
        @settingCondition="setLimitValue"
      />
      <SidebarConditionGroup
        ref="sidebarCondition"
        :selected="conditionSettingValue"
        :conditionList="conditionList"
        :memberLevel="memberLevel"
        :privilegeList="privilegeCodeList"
        :productList="productList"
        :redeemItemList="redeemItemList"
        :stampList="stampList"
        :gender="genderList"
        :customerTag="customerTag"
        :formMain="form"
        :operatorList="operatorList"
        :branchList="branchList"
        :listCondition="JSON.parse(JSON.stringify(form.condition_group))"
        @settingCondition="conditionValue"
        @setForm="setForm"
        :v="$v.form.condition_group"
        @getProductList="(page) => getProductList(page)"
        :campaignFieldConditions="campaignFieldConditions"
        :segmentList="segmentList"
      />
      <SidebarActionPanel
        ref="sidebarAction"
        :selected="actionSettingValue"
        @settingAction="actionValue"
        :dropdownAction="dropdownAction"
        :redeemCodeList="redeemCodeList"
        :missionList="missionList"
        :form="form.list_action"
        :v="$v.form.list_action"
      />
      <SidebarSMSPanel
        ref="sidebarSMS"
        :form="form.list_communication"
        @settingSMS="communicationValue"
        :formMain="form"
        typeSMS="automation"
      />
      <SidebarEmailPanel
        ref="sidebarEmail"
        :form="form.list_communication"
        @settingEmail="communicationValue"
        :formMain="form"
        typeEmail="automation"
      />
      <SidebarLinePanel
        ref="sidebarLine"
        :form="form.list_communication"
        :v="$v.form.list_communication"
        :communicationList="communicationList"
        :messageType="messageType"
        :id="id"
        :dataImage="dataImage"
        @settingLine="communicationValue"
        :formMain="form"
        typeLine="automation"
      />
      <SidebarNotificationPanel
        ref="sidebarNoti"
        :form="form.list_communication"
        @settingNotification="communicationValue"
        :formMain="form"
        typeNoti="automation"
      />

      <SidebarConsent
        ref="sidebarConsent"
        @setConsent="setConsent"
        :isConsent="form.is_not_consent_send"
      />
    </div>
    <TestSendSmsEmail
      ref="modalTestSend"
      @testSendEmailSMS="testSendEmailSMS"
    />
    <MemberTierSidebar
      ref="sidebarMemberTier"
      :selected="attrSettingValue"
      @settingAction="attrValue"
      :dropdownAction="dropdownAction"
      :redeemCodeList="redeemCodeList"
      :missionList="missionList"
      :form="form.list_attribute"
      :v="$v.form.list_attribute"
      :memberLevel="memberLevel"
      :richMenuOptions="richMenuOptions"
      @submit="attrValue"
    />
    <ModalConfirm
      ref="confirmModal"
      :text="confirmMsg"
      @handler="deleteAutomationFunc"
    />
  </div>
</template>

<script>
import GeneralInformationPanel from "@/components/automation/component/GeneralInformationPanel";
import SidebarConditionGroup from "@/components/automation/component/SidebarConditonGroup";
import SidebarLimitUser from "@/components/automation/component/SidebarLimitUser";
import SidebarActionPanel from "@/components/automation/component/SidebarActionPanel";
import SidebarSMSPanel from "@/components/automation/component/SidebarSMSPanel";
import SidebarEmailPanel from "@/components/automation/component/SidebarEmailPanel";
import SidebarLinePanel from "@/components/automation/component/SidebarLinePanel";
import SidebarNotificationPanel from "@/components/automation/component/SidebarNotificationPanel";
import DisplayCommunication from "@/components/automation/communication/DisplayCommunication";
import DisplayCommunicationLine from "@/components/automation/communication/DisplayCommunicationLine";
import DisplayResultSendMessageGroup from "@/components/automation/component/DisplayResultSendMessageGroup";
import OtherLoading from "@/components/loading/OtherLoading";
import ModalConfirm from "@/components/modal/ModalConfirm";
import SidebarConsent from "@/components/automation/component/SidebarConsent";
import DisplayOverviewReport from "@/components/automation/detail/DisplayOverviewReport";
import { required, requiredIf, url } from "vuelidate/lib/validators";

import TestSendSmsEmail from "@/components/automation/component/TestSendSmsEmail";
import Goal from "./components/Goal";
import MemberTierSidebar from "./components/MemberTier/Sidebar";
const isValidUrl = (string, form) => {
  try {
    if (form.message_type_id == 8) {
      new URL(string);
      return true;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
};
export default {
  components: {
    GeneralInformationPanel,
    SidebarConditionGroup,
    SidebarLimitUser,
    SidebarActionPanel,
    SidebarSMSPanel,
    SidebarEmailPanel,
    SidebarLinePanel,
    OtherLoading,
    DisplayCommunication,
    DisplayCommunicationLine,
    ModalConfirm,
    DisplayResultSendMessageGroup,
    DisplayOverviewReport,
    TestSendSmsEmail,
    SidebarNotificationPanel,
    SidebarConsent,
    Goal,
    MemberTierSidebar,
  },
  data() {
    return {
      eventList: [],
      conditionList: [],
      memberLevel: [],
      genderList: [],
      campaignList: [],
      missionOptions: [],
      customerTag: [],
      connectChannel: [],
      redeemCodeList: [],
      missionList: [],
      communicationList: [],
      isLoading: true,
      messageType: [],
      form: {
        name: "",
        valid_from: "",
        valid_to: "",
        event_type_id: 1,
        is_not_variable: 1,
        campaign_id: null,
        mission_id: null,
        is_not_consent_send: 0,
        active: true,
        is_draft: 0,
        sent_all: 0,
        condition_group: [
          {
            group: 1,
            import_condition: "",
            operation_type: "",
            group_operation_type: "and",
            condition: [],
          },
        ],
        list_action: [],
        list_communication: [],
        list_attribute: [],
        delay_time: 0,
        is_limit: 0,
        user_limit_days: 0,
        user_limit_times: 0,
        round_day: "",
        round_hours: "",
        round_minutes: "",
        sale_channel_id: 0,
        goal: {
          id: 0,
          marketing_automation_id: Number(this.$route.params.id),
          message_segmentation_id: 0,
          event_type_id: 6,
          is_goal: 1,
          value: 30,
        },
      },
      id: this.$route.params.id,
      selectCommu: [],
      operatorList: [
        {
          id: 1,
          name: ">",
        },
        {
          id: 2,
          name: "<",
        },
        {
          id: 3,
          name: "=",
        },
        {
          id: 4,
          name: ">=",
        },
        {
          id: 5,
          name: "<=",
        },
        {
          id: 6,
          name: "between",
        },
      ],
      fields: [
        {
          key: "id",
          label: "ID",
        },
        {
          key: "firstname",
          label: "Firstname",
        },
        {
          key: "lastname",
          label: "Lastname",
        },
        {
          key: "channel_sender",
          label: "Channel Sender",
          class: "w-150",
        },
        {
          key: "sender_time",
          label: "Sender Time",
          class: "w-150",
        },
        {
          key: "message_receiver",
          label: "Message Receiver",
        },
        {
          key: "is_goal",
          label: "Convert",
        },
      ],
      checkSentAll: false,
      items: [],
      isBusy: true,
      rows: 0,
      showingTo: 0,
      filter: {
        search: "",
        page: 1,
        take: 10,
        channel: 0,
      },
      disabledTab: true,
      dataImage: [],
      showInformation: false,
      dropdownAction: [
        { id: 1, name: "Redeem Code" },
        { id: 2, name: "Add Point" },
        { id: 3, name: "Mission" },
        { id: 6, name: "Clear Point" },
      ],

      generalInformationSelect: false,
      conditionSelect: false,
      isLimitSelect: false,

      actionSelect: false,
      setMembertierSelect: false,
      communicationSelect: false,
      showCondition: false,
      conditionSettingValue: 0,
      showAction: false,
      showCommunication: false,
      actionSettingValue: 2,
      smsEdit: false,
      emailEdit: false,
      lineEdit: false,
      notiEdit: false,
      confirmMsg: "",
      userData: {},
      headerData: {},
      privilegeCodeList: [],
      allPrivilegeCode: [],
      productList: [],
      redeemItemList: [],
      formTestSMS: {
        text: "",
        email: "",
        telephone: "",
        type_communication: 0,
        message_type_id: Number(this.$route.params.id) == 0 ? 3 : 1,
        tag_id: Number(this.$route.params.id),
      },
      campaignFieldConditions: [],
      stampList: [],
      estimatedCustomer: {
        userAll: 0,
        userSend: 0,
        userPercen: 0,
        userTelephone: 0,
        userEmail: 0,
        userLine: 0,
      },
      isConsentSet: false,
      disableStatus: false,
      tabIndex: 0,
      branchList: [],
      attrSelect: false,
      attrSettingValue: 2,
      richMenuOptions: [],
      segmentList: [],
    };
  },
  watch: {
    "form.campaign_id"(value) {
      return this.getFieldCampain(value);
    },
    tabIndex(val) {
      if (val == 1) {
        this.getReport();
        this.getUserRegister();
      }
    },
  },
  async created() {
    await this.getEvent();

    if (this.id !== "0") {
      this.isLoading = true;
      this.disabledTab = false;
      await this.getDetail();
    } else {
      this.disabledTab = true;
    }
    this.getCustomerTag();
    this.getMemberLevel();
    this.getGender();
    this.getCampagin();
    this.getSaleChannel();
    this.getRedeemCode();
    this.getMissionList();
    this.getOptionsMission();
    this.getCommunication();
    this.getMessageType();
    this.getPrivilegeCode();
    this.getProductList();
    this.getConditionList();
    this.getRedeemItem();
    this.getStampCard();
    this.getBranchList();
    this.getRichMenu();
    this.getSegmentList();
  },
  destroyed() {},
  validations: {
    form: {
      name: { required },
      valid_from: { required },
      valid_to: { required },
      round_day: {
        required: requiredIf(function (item) {
          return this.form.event_type_id === 1 || this.form.event_type_id === 5;
        }),
      },
      round_hours: {
        required: requiredIf(function (item) {
          return (
            this.form.event_type_id === 1 ||
            this.form.event_type_id === 5 ||
            this.form.event_type_id === 7
          );
        }),
      },
      round_minutes: {
        required: requiredIf(function (item) {
          return (
            this.form.event_type_id === 1 ||
            this.form.event_type_id === 5 ||
            this.form.event_type_id === 7
          );
        }),
      },
      campaign_id: {
        required: requiredIf(function (item) {
          if (this.form.event_type_id === 3) return true;
        }),
      },
      mission_id: {
        required: requiredIf(function (item) {
          if (this.form.event_type_id >= 14 && this.form.event_type_id < 18)
            return true;
        }),
      },
      sale_channel_id: {
        required: requiredIf(function (item) {
          if (this.form.event_type_id === 13) return true;
        }),
      },
      list_action: {
        $each: {
          action_type_id: {
            required: requiredIf(function (item) {
              return !item.action_type_id;
            }),
          },
          action_value: {
            required: requiredIf(function (item) {
              if (item.action_type_id != 6) return !item.action_value;
            }),
          },
        },
      },
      list_attribute: {
        $each: {
          automation_attribute_type_id: {
            required: requiredIf(function (item) {
              return this.attrSettingValue == 1;
            }),
          },
          value: {
            required: requiredIf(function (item) {
              return this.attrSettingValue == 1;
            }),
          },
        },
      },
      condition_group: {
        $each: {
          condition: {
            $each: {
              condition_type_id: {
                required: requiredIf(function (item) {
                  return !item.condition_type_id;
                }),
              },
              operation_type_id: {
                required: requiredIf(function (item) {
                  return !item.operation_type_id;
                }),
              },
              condition_value: {
                required: requiredIf(function (item) {
                  return !item.condition_value;
                }),
              },
            },
          },
        },
      },
      list_communication: {
        $each: {
          detail_list: {
            $each: {
              sort_order: { required },
              communication_value: {
                $each: {
                  value: {
                    // isValidUrl: (val, form) => isValidUrl(val, form),

                    required: requiredIf(function (item) {
                      return [7, 10, 11, 12].includes(
                        parseInt(item.message_type_id)
                      );
                    }),
                  },
                  image_url: {
                    required: requiredIf(function (item) {
                      return [7, 8, 12].includes(
                        parseInt(item.message_type_id)
                      );
                    }),
                  },
                },
              },
              format_list: {
                $each: {
                  option_name: {
                    required: requiredIf(function (item) {
                      return [7, 11].includes(parseInt(item.message_type_id));
                    }),
                  },
                  intent_name: {
                    required: requiredIf(function (item) {
                      return [7, 11].includes(parseInt(item.message_type_id));
                    }),
                    // url,
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  methods: {
    async getBranchList() {
      let data = await this.axios(`/MarketingAutomation/GetBranchList`);

      this.branchList = data.data.detail;
    },
    setForm(val) {
      this.form.condition_group = val;
    },
    modalTestSend(val) {
      this.$refs.modalTestSend.show(val);
    },
    async getProductList(page) {
      let data = await this.$store.dispatch(`getProductList`, {
        page: 1,
        search: "",
        take: 999999,
      });

      this.productList = data.detail.product_list;
    },
    deleteMessage(val) {
      if (val === 2) {
        const findIndexValue = this.form.list_communication.findIndex(
          (el) => el.communication_type_id === 2
        );
        this.form.list_communication.splice(findIndexValue, 1);
        this.smsEdit = false;
      } else if (val === 1) {
        const findIndexValue = this.form.list_communication.findIndex(
          (el) => el.communication_type_id === 1
        );
        this.form.list_communication.splice(findIndexValue, 1);
        this.emailEdit = false;
      } else if (val === 3) {
        const findIndexValue = this.form.list_communication.findIndex(
          (el) => el.communication_type_id === 3
        );
        this.form.list_communication.splice(findIndexValue, 1);
        this.lineEdit = false;
      } else if (val === 4) {
        const findIndexValue = this.form.list_communication.findIndex(
          (el) => el.communication_type_id === 4
        );
        this.form.list_communication.splice(findIndexValue, 1);
        this.notiEdit = false;
      }
    },
    conditionValue(val, form) {
      if (val) {
        this.conditionSelect = true;
        if (form) {
          this.form.list_condition = form;
        }
        this.conditionSettingValue = val;
      }
    },
    setLimitValue(val) {
      if (val) {
        this.form.is_limit = val.is_limit;
        this.isLimitSelect = true;
        this.form.user_limit_days = val.user_limit_days;
        this.form.user_limit_times = val.user_limit_times;
        this.$refs.sideBarLimitUser.hide();
      }
    },
    actionValue(val) {
      this.actionSelect = true;
      this.actionSettingValue = val;
    },
    attrValue(val) {
      if (val == 2) this.form.list_attribute = [];
      this.attrSelect = true;
      this.attrSettingValue = val;
    },
    communicationValue(val) {
      if (val === 2) {
        this.showCommunication = true;
        this.communicationSelect = true;
        this.smsEdit = true;
      } else if (val === 1) {
        this.showCommunication = true;
        this.communicationSelect = true;
        this.emailEdit = true;
      } else if (val === 3) {
        this.showCommunication = true;
        this.communicationSelect = true;
        this.lineEdit = true;
      } else if (val === 4) {
        this.showCommunication = true;
        this.communicationSelect = true;
        this.notiEdit = true;
      }
    },
    sideBarAction() {
      this.$refs.sidebarAction.show(true);
    },
    async getFieldCampain(id) {
      const res = await this.axios(
        `/MarketingAutomation/getfieldform/${id || this.form.campaign_id}`
      );

      return (this.campaignFieldConditions = res.data.detail);
    },

    async sideBarCondition(type) {
      if (this.form.campaign_id && this.form.event_type_id) {
        this.getFieldCampain();
      }
      this.handleEventTypeChange(this.form.event_type_id);
      this.$refs.sidebarCondition.show(type);
    },
    sideBarLimitUser(type) {
      this.$refs.sideBarLimitUser.show(type);
    },
    sidebarNotification() {
      const findTypeNoti = this.form.list_communication.find(
        (el) => el.communication_type_id === 4
      );
      if (!findTypeNoti) {
        this.form.list_communication.push({
          communication_type_id: 4,
          detail_list: [
            {
              communication_value: [
                {
                  value: "",
                  image_url: "",
                },
              ],
              format_list: [],
              message_type_id: 10,
              sort_order: 1,
            },
          ],
        });
      }
      this.$refs.sidebarNoti.show(true);
    },
    sideBarSMS() {
      const findTypeSMS = this.form.list_communication.find(
        (el) => el.communication_type_id === 2
      );
      if (!findTypeSMS) {
        this.form.list_communication.push({
          communication_type_id: 2,
          detail_list: [
            {
              communication_value: [
                {
                  value: "",
                  image_url: "",
                },
              ],
              format_list: [],
              message_type_id: 10,
              sort_order: 1,
            },
          ],
        });
      }
      this.$refs.sidebarSMS.show(true);
    },
    sideBarEmail() {
      const findTypeEmail = this.form.list_communication.find(
        (el) => el.communication_type_id === 1
      );
      if (!findTypeEmail) {
        this.form.list_communication.push({
          communication_type_id: 1,
          detail_list: [
            {
              communication_value: [
                {
                  value: "",
                  image_url: "",
                },
              ],
              format_list: [],
              message_type_id: 10,
              sort_order: 1,
            },
          ],
        });
      }
      this.$refs.sidebarEmail.show(true);
    },
    sideBarLine() {
      const findTypeLine = this.form.list_communication.find(
        (el) => el.communication_type_id === 3
      );
      if (!findTypeLine) {
        this.form.list_communication.push({
          communication_type_id: 3,
          detail_list: [
            {
              communication_value: [
                {
                  message_type_id: "10",
                  value: "",
                  image_url: "",
                },
              ],
              format_list: [],
              line_preview_text: "",
              message_type_id: "10",
              sort_order: 1,
            },
          ],
          line_preview_text: "",
        });
      }
      this.showCommunication = true;
      this.$refs.sidebarLine.show(true);
    },
    showCollapse(val) {
      if (val === 1) {
        if (this.showInformation === false) {
          this.showInformation = true;
        } else {
          this.showInformation = false;
        }
      } else if (val === 2) {
        if (this.showCondition === false) {
          this.showCondition = true;
          this.$refs.sidebarCondition.show(true);
        } else {
          this.showCondition = false;
        }
      } else if (val === 3) {
        if (this.showAction === false) {
          this.showAction = true;
        } else {
          this.showAction = false;
        }
      }
    },
    hideCollapse() {
      this.showInformation = false;
    },
    async getDetail() {
      this.isLoading = true;
      await this.$store.dispatch("getAutomationById", this.id);
      let data = this.$store.state.automation.respDetail;

      if (data.result == 1) {
        if (data.detail.condition_group) {
          for (const group of data.detail.condition_group) {
            var tempObj = [];
            group.group_operation_type =
              group.group_operation_type || group.condition.length > 0
                ? group.condition[0].group_operation_type || "and"
                : "and";
            for (const list of group.condition) {
              if (
                !tempObj.find(
                  (el) => el.condition_type_id == list.condition_type_id
                )
              ) {
                let value = group.condition
                  .filter(
                    (el) => el.condition_type_id == list.condition_type_id
                  )
                  .map((el) => el.condition_value);
                tempObj.push({
                  ...list,
                  condition_value: value.length == 1 ? value[0] : value,
                });
              }
            }
            group.condition = tempObj;
          }
        } else {
          data.detail.condition_group = [];
        }
        if (data.detail.event_type_id == 6)
          this.dropdownAction = [
            { id: 1, name: "Redeem Code" },
            { id: 2, name: "Add Point" },
            { id: 3, name: "Mission" },
            { id: 4, name: "Mission (With Purchase Transaction)" },
            { id: 6, name: "Clear Point" },
          ];
        this.form = data.detail;
        if (this.form.condition_group.length === 0) {
          this.conditionSettingValue = 2;
        } else {
          this.conditionSettingValue = 1;
        }
        this.checkSentAll = data.detail.sent_all;
        this.generalInformationSelect = true;
        this.showInformation = true;
        this.form.active = this.form.active === 1 ? true : false;
        this.form.campaign_id =
          this.form.campaign_id === 0 ? null : this.form.campaign_id;
        this.form.mission_id =
          this.form.mission_id === 0 ? null : this.form.mission_id;
        this.conditionSelect = true;
        this.actionSelect = true;
        console.log(this.form.list_attribute);
        if (this.form.list_attribute.length === 0) {
          this.attrSettingValue = 2;
        } else {
          this.attrSettingValue = 1;
        }
        this.attrSelect = true;
        this.isLimitSelect = true;
        this.setMembertierSelect = true;
        this.isConsentSet = true;
        this.form.round_day =
          this.form.round_day === 0 ? "" : this.form.round_day;
        this.form.goal = this.form.goal
          ? this.form.goal
          : {
              id: 0,
              marketing_automation_id: Number(this.$route.params.id),
              message_segmentation_id: 0,
              event_type_id: 0,
              is_goal: 0,
              value: 0,
            };
        this.communicationSelect = true;

        if (this.form.list_action.length == 0) {
          this.actionSettingValue = 2;
        } else {
          this.actionSettingValue = 1;
        }
        if (this.form.list_communication) {
          const findEMail = this.form.list_communication.find(
            (el) => el.communication_type_id === 1
          );
          const findSMS = this.form.list_communication.find(
            (el) => el.communication_type_id === 2
          );
          const findLine = this.form.list_communication.find(
            (el) => el.communication_type_id === 3
          );
          const findNoti = this.form.list_communication.find(
            (el) => el.communication_type_id === 4
          );
          if (findEMail) {
            this.emailEdit = true;
          }
          if (findSMS) {
            this.smsEdit = true;
          }
          if (findLine) {
            this.lineEdit = true;
          }
          if (findNoti) {
            this.notiEdit = true;
          }
        }
        this.isLoading = false;
        // this.$refs.goal.isGoalSelect = true;
      }
    },
    async getUserRegister() {
      let reqData = {
        id: this.id,
        filter: this.filter,
      };
      await this.$store.dispatch("getUserRegister", reqData);
      const data = this.$store.state.automation.userRegister;
      if (data.result == 1) {
        this.userData = data.detail;
        this.items = data.detail.result;
        this.rows = data.detail.result_count;
        this.showingTo =
          this.items?.length >= 10 ? this.showingTo : this.items.length;
        this.isBusy = false;
      }
    },
    async getReport() {
      const resp = await this.axios(
        `/MarketingAutomation/getoverviewheaderbyid/${this.$route.params.id}`
      );

      this.headerData = resp.data.detail;
    },
    getDataInPagination(value) {
      this.filter = value;
      this.getUserRegister();
    },
    searchUserRegister() {
      this.getUserRegister();
    },
    getListUser(value) {
      this.filter = value;
      this.getUserRegister();
    },
    async getPrivilegeCode() {
      // await this.$store.dispatch("getPrivilegeCodeInAutomation");
      const resp = await this.axios("/MarketingAutomation/getprivilege/0");
      const resp2 = await this.axios("/MarketingAutomation/getprivilege/1");
      this.allPrivilegeCode = resp.data.detail;
      // const data = this.$store.state.automation.privilegeCodeInAutomation;
      // if (data.result == 1) {
      this.privilegeCodeList = resp2.data.detail.map((el) => {
        return { ...el, name: `${el.name} (${el.code})` };
      });
      // }
    },
    async getRedeemItem() {
      const res = await this.axios(`/MarketingAutomation/getredeemitem`);
      if (res.data.result == 1) {
        this.redeemItemList = res.data.detail;
      }
    },
    async getStampCard() {
      await this.$store.dispatch("getStampCardInAutomation");
      const data = this.$store.state.automation.stampCardInAutomation;

      if (data.result == 1) {
        this.stampList = data.detail;
      }
      // this.stampList = data;
    },
    async getEvent() {
      await this.$store.dispatch("getEvent");
      let data = this.$store.state.automation.eventList;
      if (data.result == 1) {
        this.eventList = data.detail;
      }
    },
    async getConditionList() {
      await this.$store.dispatch("getCondition", 1);
      let data = this.$store.state.automation.conditionList;
      if (data.result == 1) {
        for (const condition of data.detail) {
          if (this.form.event_type_id == 12 && condition.id == 31)
            this.conditionList.push({
              id: condition.id,
              name: condition.name,
              type: condition.type,
            });
          else if (
            this.form.event_type_id == 6 &&
            [17, 30, 37, 38, 39, 45].includes(condition.id)
          ) {
            this.conditionList.push({
              id: condition.id,
              name: condition.name,
              type: condition.type,
            });
          } else if (
            condition.id != 37 ||
            condition.id != 38 ||
            condition.id != 17 ||
            condition.id != 39 ||
            condition.id != 31
          ) {
            this.conditionList.push({
              id: condition.id,
              name: condition.name,
              type: condition.type,
            });
          } else {
            this.conditionList.push({
              id: condition.id,
              name: condition.name,
              type: condition.type,
            });
          }
        }
      }
    },
    handleEventTypeChange(val) {
      let data = this.$store.state.automation.conditionList;
      if (val == 6)
        this.dropdownAction = [
          { id: 1, name: "Redeem Code" },
          { id: 2, name: "Add Point" },
          { id: 3, name: "Mission" },
          { id: 4, name: "Mission (With Purchase Transaction)" },
          { id: 6, name: "Clear Point" },
        ];
      else
        this.dropdownAction = [
          { id: 1, name: "Redeem Code" },
          { id: 2, name: "Add Point" },
          { id: 3, name: "Mission" },
          { id: 6, name: "Clear Point" },
        ];
      this.conditionList = [];

      let temp = [];
      for (const condition of data.detail) {
        if (condition.id != 10 && condition.id < 12)
          temp.push({
            id: condition.id,
            name: condition.name,
            type: condition.type,
          });
        else if (val == 12 && condition.id == 31)
          temp.push({
            id: condition.id,
            name: condition.name,
            type: condition.type,
          });
        else if (val == 6 && [17, 30, 37, 38, 39, 45].includes(condition.id)) {
          temp.push({
            id: condition.id,
            name: condition.name,
            type: condition.type,
          });
        } else if ([34, 35, 36, 41, 44, 46].includes(condition.id))
          temp.push({
            id: condition.id,
            name: condition.name,
            type: condition.type,
          });
      }

      this.conditionList = temp;
    },
    async getSegmentList() {
      if (this.segmentList.length > 0) return;
      const result = await this.axios("/customer/SegmentFilter");

      if (result.data.result == 1) {
        this.segmentList = result.data.detail;
      }
    },
    async getMemberLevel() {
      await this.$store.dispatch("getMemberLevel");
      let data = this.$store.state.automation.memberLevel;
      if (data.result == 1) {
        this.memberLevel = data.detail;
      }
    },
    async getGender() {
      await this.$store.dispatch("getGender");
      let data = this.$store.state.automation.genderList;
      if (data.result == 1) {
        for (const gd of data.detail) {
          if (gd.id !== "0") {
            this.genderList.push({
              id: gd.id,
              name: gd.name,
              year: gd.year,
            });
          }
        }
      }
    },
    async getCampagin() {
      await this.$store.dispatch("getCampaign");
      let data = this.$store.state.automation.campaignList;
      if (data.result == 1) {
        this.campaignList = data.detail;
      }
    },
    async getSaleChannel() {
      await this.$store.dispatch("getSaleChannel");
      let data = this.$store.state.automation.connectChannel;

      if (data.result == 1) {
        this.connectChannel = data.detail.filter((el) => el.id != 1);
      }
    },
    async getRedeemCode() {
      await this.$store.dispatch("getRedeemCode", this.id);
      let data = this.$store.state.automation.redeemCodeList;
      if (data.result == 1) {
        this.redeemCodeList = data.detail;
      }
    },
    async getCommunication() {
      await this.$store.dispatch("getCommunication");
      let data = this.$store.state.automation.communicationList;
      if (data.result == 1) {
        this.communicationList = data.detail;
      }
    },
    async getMessageType() {
      await this.$store.dispatch("getMessageType");
      let data = this.$store.state.automation.messageType;
      if (data.result == 1) {
        this.messageType = data.detail;
        this.isLoading = false;
      }
    },
    submitConditionInForm() {
      // delete field in condition
      if (this.conditionSettingValue === 1) {
        for (const group of this.form.condition_group) {
          for (const condition of group.condition) {
            if (condition.condition_type_id === 1) {
              delete condition.operation_type_name;
              delete condition.condition_type_name;
            }
            if (condition.condition_type_id === 2) {
              delete condition.operation_type_name;
              delete condition.condition_type_name;
            }
            if (
              condition.condition_type_id === 3 ||
              condition.condition_type_id === 4 ||
              condition.condition_type_id === 5
            ) {
              delete condition.operation_type_name;
              delete condition.condition_type_name;
            }
          }
        }
      } else {
        this.form.list_condition = [];
      }
    },
    submitActionInForm() {
      // delete field in action
      if (this.actionSettingValue === 1) {
        for (const action of this.form.list_action) {
          if (action.action_type_id === 1) {
            delete action.action_type_name;
          }
          if (action.action_type_id === 2) {
            delete action.pointId;
            delete action.pointName;
            delete action.action_type_name;
          }
        }
      } else {
        this.form.list_action = [];
      }
    },
    submitCommunicationInForm() {
      // delete field in communication
      for (const list of this.form.list_communication) {
        for (const detail of list.detail_list) {
          if (
            list.communication_type_id === 1 ||
            list.communication_type_id === 2
          ) {
            detail.format_list = [];
          } else if (list.communication_type_id === 3) {
            for (const commu of detail.communication_value) {
              if (commu.message_type_id) {
                delete commu.message_type_id;
              }
            }
          }
        }
      }
      // check communication
      const findCommuSMS = this.form.list_communication.filter(
        (el) => el.communication_type_id === 2
      );
      const findCommuEmail = this.form.list_communication.filter(
        (el) => el.communication_type_id === 1
      );
      const findCommuLine = this.form.list_communication.filter(
        (el) => el.communication_type_id === 3
      );
      const findCommuNoti = this.form.list_communication.filter(
        (el) => el.communication_type_id === 4
      );
      if (findCommuSMS.length > 0) {
        const chkValueSMS =
          findCommuSMS[0].detail_list[0].communication_value[0].value;
        if (!chkValueSMS) {
          const findIndexSMS = this.form.list_communication.findIndex(
            (sms) => sms.communication_type_id === 2
          );
          this.form.list_communication.splice(findIndexSMS, 1);
        }
      }
      if (findCommuEmail.length > 0) {
        const chkValueEmail =
          findCommuEmail[0].detail_list[0].communication_value[0].value;
        if (!chkValueEmail) {
          const findIndexEmail = this.form.list_communication.findIndex(
            (email) => email.communication_type_id === 1
          );
          this.form.list_communication.splice(findIndexEmail, 1);
        }
      }
      if (findCommuEmail.length > 0) {
        const chkValueEmail =
          findCommuEmail[0].detail_list[0].communication_value[0].value;
        if (!chkValueEmail) {
          const findIndexEmail = this.form.list_communication.findIndex(
            (email) => email.communication_type_id === 1
          );
          this.form.list_communication.splice(findIndexEmail, 1);
        }
      }
    },
    async sendForm(draft) {
      this.form.is_draft = draft;
      await this.submitConditionInForm();
      await this.submitActionInForm();
      await this.submitCommunicationInForm();
      await this.submitFormConfirm(draft);
    },

    submitFormConfirm(draft) {
      if (draft === 1) {
        const date = new Date();
        const dateString = this.$moment(date).format(
          "YYYY-MM-DDT00:00:00.000+07:00"
        );
        const findCondition = this.form.list_condition.some(
          (el) => el.condition_value === null
        );
        const findAction = this.form.list_action.some(
          (el) => el.action_value === null
        );
        if (findCondition) this.form.list_condition = [];
        if (findAction) this.form.list_action = [];
        if (!this.form.valid_from) this.form.valid_from = dateString;
        if (!this.form.valid_to) this.form.valid_to = dateString;
        this.isDraftAutomation();
      } else {
        if (!this.form.goal) {
          const element = document.getElementById("goal-panel");
          // element.scrollIntoView();
        }
        if (!this.generalInformationSelect) {
          this.warningAlert("กรุณาบันทึกข้อมูลทั่วไป");
          return;
        } else {
          this.$v.form.$touch();
          if (
            this.$v.form.name.$error ||
            this.$v.form.valid_from.$error ||
            this.$v.form.valid_to.$error ||
            this.$v.form.campaign_id.$error ||
            this.$v.form.mission_id.$error ||
            this.$v.form.sale_channel_id.$error
          ) {
            this.warningAlert("Please save general information");

            this.generalInformationSelect = false;
            return;
          }
        }
        if (!this.conditionSettingValue) {
          this.warningAlert("Please save condition");

          return;
        }
        if (!this.actionSettingValue) {
          this.warningAlert("Please save privilege");

          return;
        }
        if (!this.attrValue) {
          this.warningAlert("Please save Set Attribute");

          return;
        }
        if (!this.isConsentSet) {
          this.warningAlert("Please save consent status");
          return;
        }
        if (!this.$refs.goal.isGoalSelect)
          return this.warningAlert("Please save goal");

        this.isDraftAutomation();
      }
    },
    async isDraftAutomation() {
      this.isLoading = true;

      if (this.form.round_hours) {
        this.form.round_hours = parseInt(this.form.round_hours);
      } else {
        this.form.round_hours = 0;
      }
      if (this.form.round_minutes) {
        this.form.round_minutes = parseInt(this.form.round_minutes);
      } else {
        this.form.round_minutes = 0;
      }
      if (this.form.delay_time) {
        this.form.delay_time = parseInt(this.form.delay_time);
      } else {
        this.form.delay_time = 0;
      }

      for (const group of this.form.condition_group) {
        var tempObj = [];

        for (const list of group.condition) {
          delete list.id;

          list.operation_value = list.operation_value
            ? list.operation_value
            : 0;
          list.date_type = list.date_type ? list.date_type : 0;
          if (typeof list.condition_value == "object") {
            for (const value of list.condition_value) {
              tempObj.push({ ...list, condition_value: value });
            }
          } else {
            tempObj.push(list);
          }
        }

        group.condition = tempObj;
      }

      this.form.campaign_id = this.form.campaign_id
        ? parseInt(this.form.campaign_id)
        : 0;
      this.form.mission_id = this.form.mission_id
        ? parseInt(this.form.mission_id)
        : 0;
      this.form.round_day = this.form.round_day
        ? parseInt(this.form.round_day)
        : 0;
      this.form.active = this.form.active ? 1 : 0;

      if (this.id === "0") {
        await this.$store.dispatch("createAutomation", this.form);
        let data = this.$store.state.automation.respCreate;
        if (data.result === 1) {
          this.successAlert().then(() => this.$router.push("/automation"));
        } else {
          this.errorAlert(data.message);
        }
        this.isLoading = false;
      } else {
        let reqData = {
          id: this.id,
          form: this.form,
        };
        delete this.form.id;
        await this.$store.dispatch("updateAutomation", reqData);
        let data = this.$store.state.automation.respUpdateAutomation;
        if (data.result == 1) {
          this.successAlert().then(() => this.$router.push("/automation"));
        } else {
          this.errorAlert(data.message);
        }
        this.isLoading = false;
      }
    },
    saveInformation() {
      this.$v.form.$touch();
      if (
        this.$v.form.name.$error ||
        this.$v.form.valid_from.$error ||
        this.$v.form.valid_to.$error ||
        this.$v.form.campaign_id.$error ||
        this.$v.form.mission_id.$error ||
        this.$v.form.sale_channel_id.$error
      ) {
        this.generalInformationSelect = false;
        return;
      } else {
        this.generalInformationSelect = true;
      }
    },
    deleteData() {
      this.confirmMsg = "Confirm Deletion Automation?";
      this.deleteAutomationFunc();
    },
    async deleteAutomationFunc() {
      this.confirmAlert({ message: "Confirm Deletion Automation ?" }).then(
        async ({ value }) => {
          if (value) {
            this.isLoading = true;
            await this.$store.dispatch("deleteAutomation", this.id);
            let data = this.$store.state.automation.respDelete;
            if (data.result == 1) {
              this.successAlert().then(() => this.$router.push("/automation"));
            } else {
              this.isLoading = false;
              this.errorAlert(data.message);
            }
          }
        }
      );
    },
    async testSendEmailSMS(val) {
      if (val.type === 2) {
        this.formTestSMS.telephone = val.data;
        this.formTestSMS.email = "";
        this.formTestSMS.type_communication = 2;
        let SMS = this.form.list_communication.find(
          (el) => el.communication_type_id === 2
        );
        this.formTestSMS.text = SMS.detail_list[0].communication_value[0].value;
      } else if (val.type === 1) {
        this.formTestSMS.email = val.data;
        this.formTestSMS.telephone = "";
        this.formTestSMS.type_communication = 1;
        let SMS = this.form.list_communication.find(
          (el) => el.communication_type_id === 1
        );
        this.formTestSMS.text = SMS.detail_list[0].communication_value[0].value;
        this.formTestSMS.subject =
          SMS.detail_list[0].communication_value[0].subject;
      } else if (val.type === 4) {
        this.formTestSMS.telephone = val.data;
        this.formTestSMS.email = "";
        this.formTestSMS.type_communication = 4;
        let SMS = this.form.list_communication.find(
          (el) => el.communication_type_id === 4
        );
        this.formTestSMS.text = SMS.detail_list[0].communication_value[0].value;
      } else if (val.type === 3) {
        // SMS;

        this.formTestSMS.telephone = val.data;
        this.formTestSMS.email = "";
        this.formTestSMS.type_communication = val.type;
        let SMS = this.form.list_communication.find(
          (el) => el.communication_type_id === val.type
        );
        this.formTestSMS.line_detail = SMS.detail_list;
        this.formTestSMS.line_preview_text = SMS.line_preview_text;
      }
      await this.actionTestSendEmailSMS();
    },
    async actionTestSendEmailSMS() {
      await this.$store.dispatch("testSendSMSOrEmail", this.formTestSMS);
      const data = this.$store.state.automation.stateTestSendSMSOrEmail;
      this.successAlert();
    },
    setConsent(val) {
      this.form.is_not_consent_send = val;

      this.isConsentSet = true;
    },
    sidebarConsent() {
      if (!this.disableStatus) this.$refs.sidebarConsent.show(true);
    },
    submitGoal(val) {
      this.$refs.goal.isGoalSelect = true;

      this.form.goal = val;
    },
    async getMissionList() {
      // await this.$store.dispatch("getRedeemCode", this.id);
      const data = await this.axios("/marketingautomation/getmission");

      if (data.data.result == 1) {
        this.missionList = data.data.detail;
      }
    },
    async getOptionsMission() {
      // await this.$store.dispatch("getRedeemCode", this.id);
      const data = await this.axios("/mission/mission_list");

      // if (data.data.result == 1) {
      this.missionOptions = data.data;
      // }
    },
    async getCustomerTag() {
      // await this.$store.dispatch("getRedeemCode", this.id);
      const data = await this.axios("/MarketingAutomation/getcustomertag");

      // if (data.data.result == 1) {
      this.customerTag = data.data.detail;
      // }
    },

    async getRichMenu() {
      if (this.richMenuOptions.length > 0) return;
      const res = await this.axios("/marketingautomation/getlinerich");

      this.richMenuOptions = res.data.detail;
    },
  },
};
</script>

<style lang="scss" scoped>
.marginbottom {
  margin-bottom: 0rem;
}
::v-deep .nav-tabs .nav-link:not(.active):hover {
  background-color: transparent;
  color: var(--primary-color);
  border-bottom: 4px solid var(--primary-color);
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
}
::v-deep .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: transparent !important;
  color: var(--primary-color) !important;
  border-bottom: 4px solid var(--primary-color) !important;
}
.bg-tabs-custom {
  text-align: left;
  border: none;
  background: var(--theme-secondary-color);
  border-radius: 0px;
  color: var(--font-color);
  font-weight: 600;
  padding: 10px 0;
}
.ft-20 {
  font-size: 20px;
}
.btn-edit {
  color: var(--primary-color);
  border: none;
  background-color: transparent;
  font-weight: 600;
  padding: 0 !important;
}
::v-deep .header-tablepage {
  color: #092d53;
}
.btn-edit-general {
  border: none;
  background-color: transparent;
  text-decoration: underline;
  color: var(--primary-color);
  border-radius: 0px;
}
.btn-line-right {
  border-right: 1px solid var(--primary-color);
}
.btn-template {
  color: var(--primary-color);
  border-color: var(--primary-color);
  background-color: transparent;
  border-radius: 0px;
  width: 30%;
}
.btn-confirm-automation {
  color: #fff;
  border-radius: 0px;
  border-color: var(--primary-color);
  background-color: var(--primary-color);
  width: 30%;
}
.btn-cancel-automation {
  color: #fff;
  background-color: #39424e;
  border-radius: 0px;
  width: 30%;
  border: 1px solid #39424e;
}
.btn-delete-automation {
  background-color: transparent;
  border: 1px solid #39424e;
  border-radius: 0px;
  width: 30%;
  color: #39424e;
}
.add-new {
  text-decoration: underline;
}
.black-color {
  color: #000;
}
.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.text-edit-communication {
  color: gray;
  font-weight: 100;
}
.pd-bt-100 {
  padding-bottom: 100px;
}
.btn-expand {
  padding: 0;
  background-color: transparent;
  border: none;
}
::v-deep .btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  background-color: transparent;
  border-color: transparent;
}
#collapseExample {
  border: 0;
}
</style>
