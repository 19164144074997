<template>
  <b-modal v-model="isShow" ref="" centered @hidden="hide">
    <template #modal-header>{{ title }}</template>

    <b-table
      responsive
      striped
      hover
      show-empty
      empty-text="No matching records found"
      class="table-main table-custom"
      :no-border-collapse="false"
      :busy="isBusy"
      :items="items"
      :fields="fields"
    >
      <template v-slot:cell(note)="{ item }">
        {{ item.note || "-" }}
      </template>
      <template v-slot:cell(product_image)="{ item }">
        <img
          :src="item.product_image"
          class="img-size"
          v-if="item.product_image"
        />
      </template>
      <template v-slot:cell(headquarters_note)="{ item }">
        {{ item.headquarters_note || "-" }}
      </template>
    </b-table>
    <hr />
    <InputSelect
      v-if="isApprove"
      title="แอคชั่น"
      isRequired
      class="mt-2"
      v-model="form.rqp_action_type_id"
      v-bind:options="actions"
      valueField="value"
      textField="text"
      @onDataChange="handleChangeAction"
    >
      <template v-slot:option-first>
        <b-form-select-option :value="0" disabled
          >-- เลือกแอคชั่น --</b-form-select-option
        >
      </template>
    </InputSelect>

    <InputSelect
      v-if="isApprove && form.rqp_action_type_id == 1"
      title="จากสาขา"
      isRequired
      class="mt-2"
      :v="$v.form.from_branch_id"
      :isValidate="$v.form.from_branch_id.$error"
      v-model="form.from_branch_id"
      v-bind:options="branchs"
      valueField="branch_id"
      textField="branch_name"
      @onDataChange="getTrn"
    >
      <template v-slot:option-first>
        <b-form-select-option :value="0" disabled
          >-- เลือกสาขา --</b-form-select-option
        >
      </template>
    </InputSelect>

    <InputSelect
      v-if="isApprove && form.rqp_action_type_id == 1"
      title="การโอนสินค้า"
      isRequired
      class="mt-2"
      v-model="form.inventory_transfer_document_id"
      :v="$v.form.inventory_transfer_document_id"
      :isValidate="$v.form.inventory_transfer_document_id.$error"
      v-bind:options="trn"
      :disabled="!form.from_branch_id"
      valueField="document_id"
      textField="document_name"
    >
      <template v-slot:option-first>
        <b-form-select-option :value="0" disabled
          >-- เลือกการโอนสินค้า --</b-form-select-option
        >
      </template>
    </InputSelect>

    <InputTextArea
      textFloat="หมายเหตุ"
      type="text"
      isRequired
      class="custom-input"
      placeholder="หมายเหตุ"
      :v="$v.form.headquarters_note"
      :isValidate="$v.form.headquarters_note.$error"
      rows="3"
      v-model="form.headquarters_note"
    ></InputTextArea>

    <template #modal-footer>
      <b-row>
        <b-col class="text-left">
          <b-button @click.prevent="isShow = false" class="border-btn"
            >Cancel</b-button
          >
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button class="submit-btn" @click="submit()">Save</b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import InputSelect from "@/components/inputs/InputSelect";
import InputTextArea from "@/components/inputs/InputTextArea";
import { required } from "vuelidate/lib/validators";

export default {
  props: {
    branchId: {
      required: false,
      type: Number,
    },
  },
  componesnt: {
    InputSelect,
    InputTextArea,
  },

  data() {
    return {
      isShow: false,
      fields: [
        { label: "รหัสสินค้า", key: "barcode" },
        {
          label: "Name",
          key: "product_name",

          thStyle: { width: "20%" },
        },
        { label: "Image", key: "product_image", thStyle: { width: "20%" } },
        { label: "หมายเหตุ(สาขา)", key: "note", thStyle: { width: "10%" } },
        {
          label: "หมายเหตุ(HQ)",
          key: "headquarters_note",
          thStyle: { width: "10%" },
        },
        { label: "Quantity", key: "quantity", thStyle: { width: "10%" } },
      ],
      isApprove: 0,
      isBusy: false,
      items: [],
      title: [],
      actions: [
        { text: "โอนสินค้า", value: 1 },
        { text: "สั่งผลิต", value: 2 },
      ],
      branchs: [],
      form: {
        id: null,
        from_branch_id: null,
        inventory_transfer_document_id: null,
        rqp_action_type_id: null,
        headquarters_note: "",
      },
      product_id: null,
      trn: [],
    };
  },
  validations: {
    form: {
      rqp_action_type_id: {
        required,
      },
      from_branch_id: {
        required,
      },
      inventory_transfer_document_id: {
        required,
      },
      headquarters_note: { required },
    },
  },
  methods: {
    async show(isApprove, item) {
      this.isApprove = isApprove;
      this.isShow = true;
      this.title = isApprove ? "Approve" : "Reject";
      this.product_id = item.id;
      this.form.id = this.product_id;
      this.items = [item];

      this.form.rqp_action_type_id = 0;

      this.form.from_branch_id = 0;
      this.form.inventory_transfer_document_id = 0;
      this.form.rqp_action_type_id = 0;

      // await this.getAvailableBranch();
    },
    async getAvailableBranch() {
      const res = await this.axios(
        `/rqp/Product/${this.product_id}/AvailableBranch`
      );
      if (res.data.result) {
        this.branchs = res.data.detail;
      } else {
        this.errorAlert(res.data.message);
      }
    },
    async getTrn() {
      let payload = {
        source_branch_id: this.form.from_branch_id,
        destination_branch_id: this.branchId,
      };
      console.log(payload);
      const res = await this.axios.post(
        `/rqp/GetInventoryTransferDocumentList`,
        payload
      );
      if (res.data.result) {
        this.trn = res.data.detail;
        this.form.inventory_transfer_document_id = this.trn[0].document_id || 0;
      } else {
        this.errorAlert(res.data.message);
      }
    },
    hide() {
      this.form = {
        id: null,
        from_branch_id: null,
        inventory_transfer_document_id: null,
        rqp_action_type_id: null,
        headquarters_note: "",
      };
      this.$v.form.$reset();
      this.isShow = false;
    },
    async handleChangeAction(val) {
      this.form.from_branch_id = 0;
      this.form.inventory_transfer_document_id = 0;
      if (val == 1) {
        await this.getAvailableBranch();
      }
    },
    submit() {
      this.$v.form.$touch();

      if (this.$v.form.$error) return;
      this.$emit("updateProduct", this.form);

      this.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  background-color: var(--theme-secondary-color);
  color: var(--font-color);
  font-weight: 600;
}
::v-deep .modal-dialog {
  max-width: 800px;
}
::v-deep .input-group-text {
  padding: 0;
  background-color: transparent;
}
::v-deep thead {
  background-color: var(--theme-secondary-color);
  color: var(--font-color);
  text-align: center;
}
::v-deep .modal-footer {
  justify-content: space-between;
}
.btn-save {
  color: #fff;
  background-color: var(--primary-color);
  width: 100%;
  border: none;
  padding: 5px 50px;
  border-radius: 0px;
}
.btn-cancel {
  color: #fff;
  background-color: #39424e;
  width: 100%;
  border: none;
  padding: 5px 50px;
  border-radius: 0px;
}

.img-size {
  width: 60px;
  height: 60px;
}
</style>
