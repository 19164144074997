<template>
  <div>
    <div class="mb-2"><b>Notes</b></div>
    <div class="bg-note">
      <b-row>
        <b-col>
          <div class="detail-note">
            <span>
              <font-awesome-icon icon="clock" class="icon-bd" />
              <span class="ml-2 color-no-edit">{{
                $moment(noteList.last_updated).format("DD/MM/YYYY HH:mm:ss")
              }}</span>
            </span>
          </div>
        </b-col>
        <b-col class="text-right">
          <div class="color-primary m-2 pointer" v-if="edit" @click="editNote">
            <font-awesome-icon
              icon="pencil-alt"
              size="sm"
              v-if="$store.state.permission.is_has_permission_edit_customer"
            />
            <!-- <span class="ml-1 edit-btn">Edit</span> -->
          </div>
          <div class="color-primary m-2 pointer" v-if="save" @click="saveNote">
            <font-awesome-icon icon="save" size="sm" />
            <span class="ml-1 edit-btn">Save</span>
          </div>
        </b-col>
      </b-row>
      <div>
        <div v-if="edit">
          <div class="p-3 color-no-edit">
            <div
              class="mission-description truncate--line-clamped"
              ref="description"
            >
              {{ noteList.note }}
            </div>

            <div class="text-center my-2" v-if="descriptionHeight >= 100">
              <b-button
                class="color-primary f-bold f-md"
                variant="custom-link"
                @click="moreDesc()"
              >
                <span v-if="flagMoreDesc">
                  Show Less
                  <font-awesome-icon
                    size="sm"
                    icon="chevron-up"
                    class="color-gray"
                  />
                </span>
                <span v-else>
                  Show More
                  <font-awesome-icon
                    size="sm"
                    icon="chevron-down"
                    class="color-gray"
                  />
                </span>
              </b-button>
            </div>
            <!-- <b-form-textarea
              v-model=""
              placeholder="Add item..."
              readonly
            ></b-form-textarea> -->
          </div>
        </div>
        <div class="p-3" v-if="save">
          <b-form-textarea
            v-model="noteList.note"
            placeholder="Add item..."
            rows="10"
          ></b-form-textarea>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    noteList: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      edit: true,
      save: false,
      descriptionHeight: 0,
      flagMoreDesc: false,
    };
  },
  watch: {
    "noteList.note"(val) {
      this.$nextTick(() => {
        this.descriptionHeight = this.$refs.description.clientHeight;
      });
    },
  },
  mounted() {},
  methods: {
    editNote() {
      this.save = true;
      this.edit = false;
    },
    saveNote() {
      this.$emit("updateNote", this.noteList.note);
      this.save = false;
      this.edit = true;
      this.$nextTick(() => {
        this.descriptionHeight = this.$refs.description.clientHeight;
      });
    },
    moreDesc() {
      this.$nextTick(() => {
        if (this.$refs.description.className.includes("show")) {
          this.$refs.description.classList.remove("show");
          setTimeout(() => {
            this.$refs.description.classList.add("truncate--line-clamped");
          }, 100);
          this.flagMoreDesc = false;
        }
        // this.$refs.description.style.maxHeight = "500px";
        else {
          this.$refs.description.classList.remove("truncate--line-clamped");
          this.$refs.description.classList.add("show");

          this.flagMoreDesc = true;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-note {
  background-color: #e9e9e9;
  border-radius: 5px;
}
.edit-btn {
  text-decoration: underline;
}
.color-edit-btn {
  color: #0098f7;
  padding: 10px;
}
.color-no-edit {
  color: #000;
}
::v-deep .form-control:disabled,
.form-control[readonly] {
  background-color: #e9e9e9;
  opacity: 1;
  border: none;
  color: #000;
  overflow-y: hidden !important;
}
.icon-bd {
  color: var(--primary-color);
}
.detail-note {
  padding: 10px;
}
.truncate--line-clamped {
  -webkit-line-clamp: 5;
}
.mission-description.show {
  max-height: 100vh;
}
.mission-description {
  // img {
  //   width: 100%;
  // }
  white-space: break-spaces;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  max-height: 120px;
  overflow: hidden;
  overflow: hidden;
  transition: max-height 0.85s ease;
}
</style>
